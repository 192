import React, { useState } from 'react';
import { IJsonConfig } from '../types/json-editor';
import { useTheme } from '@emotion/react';
import Cell, { ICell, InlineInput } from '../stack/cell';
import { getJSONSettings } from './settings';
import Icon from '../components/icon';
import { observer } from 'mobx-react';

interface IJSONCell extends ICell {
  json: any;
  onRefresh: () => void;
  onDataUpdate: (path: (string | number)[], value: any) => void;
  onDataRename: (path: (string | number)[], newName: string) => void;
}

interface IJSONRecursive {
  json: any;
  path: (string | number)[];
  indent: number;
  name?: string;
  appendComma?: boolean;
  expanded?: boolean;

  settings?: Partial<IJsonConfig>;

  doUpdate: (path: (string | number)[], value: any) => void;
  doRename: (path: (string | number)[], newName: string) => void;
}

const limitSchemeObjPreview = (keys: string[], threshold: number): string => {
  const result: string[] = [];
  let total = 4;

  for (const key of keys) {
    const len = key.length;
    if (total + len > threshold) {
      return `{ ${result.concat('...').join(', ')} }`;
    } else {
      result.push(key);
      total += len + 2;
    }
  }

  return `{ ${result.join(', ')} }`;
};

const determineScheme = (jsonArr: any[]): string | string[] | undefined => {
  if (jsonArr.length > 1000) {
    return undefined;
  }

  let objScheme = undefined;
  let scheme = undefined;
  for (const i of jsonArr) {
    let t: string = typeof i;
    if (t === 'object') {
      if (Array.isArray(i)) {
        t = 'array';
      } else {
        objScheme = Object.keys(i);
        t = `{ ${objScheme.join(', ')} }`;
      }
    }

    if (scheme === undefined) {
      scheme = t;
      continue;
    }

    if (t !== scheme) {
      return undefined;
    }
  }

  return objScheme ?? scheme;
};

const FieldName: React.FC<{ fieldName?: string, settings: IJsonConfig, onChange: (newName: string) => void }> = (props) => {
  const { fieldName, settings, onChange } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [nameValue, setNameValue] = useState<string>(fieldName ?? '');

  const theme = useTheme();

  if (!fieldName) {
    return null;
  }

  const onFinish = () => {
    setIsEditing(false);
    if (!nameValue || !nameValue.trim().length) {
      return;
    }

    if (nameValue === fieldName) {
      return;
    }

    onChange(nameValue);
  };

  const content = isEditing ? (
    <InlineInput
      value={nameValue}
      onChange={setNameValue}
      onFinish={onFinish}
      placeholder={'name'}
      strictWidth={true}
    />
  ) : (
    <span onClick={() => setIsEditing(true)}>{
      settings.keyQuoting
        ? `"${fieldName}"`
        : `${fieldName}`
    }</span>
  );

  return (
    <span>
      {content}
      <span style={{ color: theme.json.semicolonColor, marginRight: '0.65ch' }}>
        {':'}
      </span>
    </span>
  );
};

const JSONPrimitive: React.FC<IJSONRecursive> = props => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingValue, setEditingValue] = useState<any>();

  const { json, name: fieldName, appendComma, path, doUpdate, doRename } = props;
  const settings = getJSONSettings(props.settings);

  const theme = useTheme();

  let valueColor: string;
  if (json === null || json === undefined) {
    valueColor = theme.json.nullColor;
  } else if (typeof json === 'number') {
    valueColor = theme.json.numberColor;
  } else if (typeof json === 'string') {
    valueColor = theme.json.stringColor;
  } else if (typeof json === 'boolean') {
    valueColor = theme.json.booleanColor;
  } else {
    valueColor = theme.json.defaultColor;
  }

  const onClick = () => {
    console.log({ action: 'click', path, value: json });

    if (typeof json === 'boolean') {
      doUpdate(path, !json);
    } else if (typeof json === 'string') {
      setIsEditing(true);
      setEditingValue(json);
    } else if (typeof json === 'number') {
      setIsEditing(true);
      setEditingValue(json + '');
    }
  };

  const onInputDone = () => {
    setIsEditing(false);
    setEditingValue(editingValue);

    let val = editingValue;
    if (typeof json === 'number') {
      val = +editingValue;
      if (isNaN(val)) {
        val = json;
      }
    }

    doUpdate(path, val);
  };

  const onRename = (newName: string) => {
    doRename(path, newName);
  };

  const valueStyle = {
    color: valueColor,
  };

  console.log(path, JSON.stringify(props.json, null, 1));

  let body;

  if (isEditing) {
    // const inputStyle = {
    //   ...valueStyle,
    //   width: editingValue.length + 'ch',
    //   border: '1px solid gold',
    //   fontFamily: 'monospace',
    //   letterSpacing: 0,
    // }

    body = (
      <InlineInput
        value={editingValue}
        onChange={setEditingValue}
        onFinish={onInputDone}
        placeholder="value"
        style={valueStyle}
      />
    );
  } else {
    body = (
      <span style={valueStyle} onClick={onClick}>
        {JSON.stringify(props.json, null, 1)}
      </span>
    );
  }

  return <div style={{ display: 'flex' }}>
    <FieldName fieldName={fieldName} settings={settings} onChange={onRename}/>
    {body}
    {appendComma ? ',' : ''}
  </div>;
};

export const JSONRecursive: React.FC<IJSONRecursive> = observer((props) => {
  const [expanded, setExpanded] = useState<boolean>(!!props.expanded);
  // const [isActive, setIsActive] = useState<boolean>(false);

  const theme = useTheme();

  const { json, name: fieldName, appendComma, settings: overriddenSettings, path } = props;
  let indent = props.indent;

  const settings = getJSONSettings(overriddenSettings);

  const onRename = (newName: string) => {
    props.doRename(path, newName);
  };

  if (json !== null && json !== undefined && typeof json === 'object') {
    const isArray = Array.isArray(json);
    const brackets = isArray ? ['[', ']'] : ['{', '}'];

    const indentStyle = (indent === 0 && !settings.enableBrackets)
      ? {}
      : { paddingLeft: settings.paddingSize + 'em' };

    if (!expanded) {
      let shortPreview;
      if (settings.previewsEnabled) {
        if (isArray) {
          const scheme = determineScheme(json);
          let s;
          if (scheme === undefined) {
            s = '';
          } else if (typeof scheme === 'string') {
            s = ' of ' + scheme;
          } else {
            s = ' of ' + limitSchemeObjPreview(scheme, settings.previewsThreshold);
          }
          shortPreview = `[${json.length}]${s}`;
        } else {
          shortPreview = limitSchemeObjPreview(Object.keys(json), settings.previewsThreshold);
        }
      }

      return <div style={{ display: 'flex' }}>
        <FieldName fieldName={fieldName} settings={settings} onChange={onRename}/>
        <Icon icon={'expand_more'} onClick={() => setExpanded(true)} style={'primary_hint'} paddingLeft={0}/>
        {settings.previewsEnabled ? (
          <span style={{ color: theme.primary_hint.color }}>{shortPreview}</span>
        ) : null}
      </div>;
    }

    const isRetractNearBrackets = !settings.enableBrackets || settings.expandAlwaysOnTop;
    const retract = (
      <Icon
        icon={'expand_less'}
        onClick={() => setExpanded(false)}
        style={'primary_hint'}
        paddingLeft={isRetractNearBrackets ? undefined : 0}
      />
    );

    const onNewFieldCreate = () => {
      if (props.doUpdate) {
        if (isArray) {
          const scheme = determineScheme(json);
          const newObj: any = Array.isArray(scheme) ? {} : null;
          if (scheme && newObj) {
            for (const key of scheme) {
              newObj[key] = null;
            }
          }
          props.doUpdate(path, json.concat(newObj));
          return;
        }

        let index = 0;
        let lastFieldName = 'newField';
        while (true) {
          if (!Object.keys(json).includes(lastFieldName)) {
            break;
          }

          index++;
          lastFieldName = 'newField' + index;
        }

        props.doUpdate(path.concat(lastFieldName), null);
      }
    };

    const newField = (
      <Icon
        icon={'add'}
        onClick={onNewFieldCreate}
        style={'primary_hint'}
        // paddingLeft={isRetractNearBrackets ? undefined : 0}
      />
    );
    const fieldNameWithBracket = (
      <span>
        <FieldName fieldName={fieldName} settings={settings} onChange={onRename}/>
        {settings.enableBrackets ? brackets[0] : ''}
      </span>
    );
    const bottomBracket = <span>{settings.enableBrackets ? brackets[1] : ''}{appendComma ? ',' : ''}</span>;

    let header, footer;
    if (isRetractNearBrackets) {
      header = (
        <div style={{ display: 'flex' }}>
          {fieldNameWithBracket}
          {retract}
          {newField}
        </div>
      );
      footer = bottomBracket;
    } else {
      header = (
        <div style={{ display: 'flex' }}>
          {fieldNameWithBracket}
          {newField}
        </div>
      );
      footer = (
        <div style={{ display: 'flex' }}>
          {bottomBracket}
          {retract}
        </div>
      );
    }

    return <div>
      {header}
      <div style={indentStyle}>
        {Object.keys(json).map((key, i, arr) => (
          <JSONRecursive
            key={key}
            json={json[key]}
            indent={indent + 1}
            name={key}
            settings={settings}
            appendComma={settings.commasOnEnd && i !== arr.length - 1}
            path={path.concat(key)}
            doUpdate={props.doUpdate}
            doRename={props.doRename}
          />
        ))}
      </div>
      {footer}
    </div>;
  }

  return (
    <JSONPrimitive {...props}/>
  );
});

const JSONCell: React.FC<IJSONCell> = (props) => {
  return <Cell {...props}>
    <JSONRecursive
      json={props.json}
      indent={0}
      expanded={true}
      path={[]}
      doUpdate={props.onDataUpdate}
      doRename={props.onDataRename}
    />
    <Icon icon={'refresh'} onClick={props.onRefresh}/>
  </Cell>;
};

export default JSONCell;