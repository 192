import { makeAutoObservable } from 'mobx';
import { IConfig } from '../types';
import { getJSONSettings } from '../json-editor/settings';
import { createContext } from 'react';

export const defaultConfig: IConfig = {
  theme: {
    globalFontSize: 20,
    backgroundColor: '#202028',
    mainTextColor: '#fff',
    pageTitleColor: '#fff',
    commandsTextColor: '#fff',
    primary: { color: '#19676a' },
    // primary: { color: '#279891' },
    secondary: { color: '#279891', outlined: true },
    // secondary: { color: '#19676a', outlined: true },
    default: { color: '#3a3a52' },
    primary_hint: { color: '#FFFFFF4D' },
    default_hint: { color: 'rgba(107,107,154,0.55)' },
    json: {
      nullColor: '#CC4432',
      numberColor: '#3e69c4',
      stringColor: '#35b452',
      booleanColor: '#b0aa2b',
      semicolonColor: '#999',
      defaultColor: '#fff',
    },
  },
  jsonEditor: getJSONSettings(),
  formatting: {
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
  }
};

export class ConfigStore {
  config: IConfig = defaultConfig;

  constructor() {
    makeAutoObservable(this);
  }

  update = (path: (string|number)[], value: any) => {
    let obj: any = this.config;
    for (const i of path.slice(0, path.length - 1)) {
      obj = obj[i];
    }
    obj[path[path.length - 1]] = value;

    // Quick hack to re-render app if theme changing,
    // I honestly tried to find more elegant solution :D
    if (path && path.length && path[0] === 'theme') {
      this.config.theme = { ...this.config.theme }
    }
  }
}

export const ConfigContext = createContext<ConfigStore>(new ConfigStore());
