import { IAppState } from '../types';
import { initialAppState } from './app-state.context';
import { useState } from 'react';

export interface IAppStateCtx extends IAppState {}

const useAppState = (): IAppStateCtx => {
  const [state, setState] = useState(initialAppState);

  return {
    ...state,
  }
}

export default useAppState;
