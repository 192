import KeyboardRoot from '../keyboard';
import Stack from '../stack';
import AppStateContext from '../state/app-state.context';
import useAppState from '../state/app-state.hook';
import { ThemeProvider } from '@emotion/react';
import Icon from '../components/icon';
import styled from '@emotion/styled';
import StackStore from '../state/stack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { deleteStackByName, getStacks, upsertStack } from '../api/storing-api';
import { CellContainer, CellContainerStyled } from '../stack/cell-container';
import { ConfigContext, ConfigStore } from '../state/config-store';
import useIsMobile from '../utils/is-mobile-hook';


const App = observer(() => {
  const appState = useAppState();
  const [stackInstance] = useState(new StackStore());
  const [configInstance] = useState(new ConfigStore());

  const isMobile = useIsMobile();

  const switchStack = (newName: string | null) => {
    if (!stackInstance.isEmpty) {
      if (stackInstance.isUnnamed) {
        stackInstance.rename(stackInstance.id);
      }

      upsertStack(stackInstance);
    }

    if (!newName) {
      stackInstance.loadEmpty();
      return;
    }

    stackInstance.load(getStacks().find(s => s.name === newName))
    // replaceStackInstance(getStacks().find(s => s.name === newName) || stackInstance);
  }

  return (
    <AppStateContext.Provider value={appState}>
      <ConfigContext.Provider value={configInstance}>
        <ThemeProvider theme={configInstance.config.theme}>
          <AppLayout>
            {!isMobile ? <Header stack={stackInstance} onStackChange={switchStack}/> : null}
            <Stack stack={stackInstance}/>
            {isMobile ? <KeyboardRoot stack={stackInstance} /> : null }
            {!isMobile ?  <Footer/> : null}
          </AppLayout>
        </ThemeProvider>
      </ConfigContext.Provider>
    </AppStateContext.Provider>
  )
});

const AppLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: ${props => props.theme.globalFontSize}px;
  color: ${props => props.theme.mainTextColor};
  background: ${props => props.theme.backgroundColor};
`

const FooterContainer = styled.footer`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;

  padding-top: 1em;
  padding-bottom: 2em;
  font-size: 0.6em;
  color: ${props => props.theme.default.color};
  
  a {
    color: ${props => props.theme.default_hint.color};
  }
  
  span {
    width: 100%;
    text-align: center;
  }
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 72%;
  margin: 1em auto 0;

  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

const SubHeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 90%;
  margin: 1em auto 0;
  
  & > * {
    margin: 0.5em;
    //margin-left: 1em;
    font-size: 0.7em;
  }
`;

const StyledNameInput = styled.input`
  background: none;
  outline: none;
  border: 0;
  padding: 0;
  margin: 0 1em 0 0;
  
  color: white;
  font-size: 1em;
  
   &::placeholder {
     color: ${props => props.theme.default_hint.color};
   }
`;

const StyledNameSpan = styled.span`
  margin-right: 1em;
  color: ${props => props.theme.default_hint.color};
`;

const AxorTitle = styled.h1`
  font-weight: normal;
  font-family: 'Varela Round', sans-serif;
  color: ${props => props.theme.pageTitleColor};
`;

const Header: React.FC<{ stack: StackStore, onStackChange: (name: string | null) => void }> =
  observer(({ stack, onStackChange }) => {
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const [previousName, setPreviousName] = useState<string | null>(null);
  const [menuShown, setMenuShown] = useState<boolean>(false);
  const [stackDeleteWarningShown, setStackDeleteWarningShown] = useState<boolean>(false);

  const stacks = useMemo(
    getStacks, [
      isEditingName,
      stackDeleteWarningShown,
      stack.id,
    ]);

  const onSave = useCallback(() => {
    if (!isEditingName) {
      if (!stack.isUnnamed) {
        upsertStack(stack);
        return;
      }

      setIsEditingName(true);
      return;
    }

    setIsEditingName(false);

    if (stack.isUnnamed || !stack.name) {
      if (previousName) {
        stack.rename(previousName);
        setPreviousName(null);
        return;
      } else {
        stack.rename(stack.id);
        setPreviousName(stack.id);
      }
    }

    if (previousName) {
      deleteStackByName(previousName);
      setPreviousName(null);
    }

    upsertStack(stack);
  }, [
    isEditingName, stack, upsertStack,
    setIsEditingName, previousName, setPreviousName,
    deleteStackByName,
  ]);

  const switchStackHotkey = useCallback((dir: -1 | 1) => {
    let idx = stacks.findIndex(s => s.id === stack.id);
    if (idx === -1) {
      // assuming new stack as being rightmost
      idx = stacks.length;
    }

    idx = idx + dir;
    const newStack = stacks[idx];
    if (newStack) {
      onStackChange(newStack.name);
    } else if (idx >= stacks.length) {
      // if goes beyond right edge - create new stack
      onStackChange(null);
    }
  }, [stacks, onStackChange]);

  const handleDocumentKeyEvent = useCallback((e: any) => {
    console.log({ e });
    const dir = (e.key === '`') ? -1 : (e.key === '1' ? 1 : 0)
    if (e.ctrlKey && dir !== 0) {
      switchStackHotkey(dir);
      e.preventDefault();
    } else if (e.ctrlKey && e.key === 's') {
      onSave();
      e.preventDefault();
    }
  }, [switchStackHotkey, onSave]);

  useEffect(() => {
    document.addEventListener(
      'keydown',
      handleDocumentKeyEvent,
    );

    return () => {
      document.removeEventListener(
        'keydown',
        handleDocumentKeyEvent,
      );
    };
  }, [handleDocumentKeyEvent]);

  const startRename = () => {
    setIsEditingName(true);
    setPreviousName(stack.name);
  }

  const onNameInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSave();
    }
  };

  const onStackDelete = (stackName: string) => {
    if (stackName === stack.name) {
      onStackChange(null); // go to empty stack first
    }

    // "warning shown" is nothing more than a joke currently
    setStackDeleteWarningShown(true);
    deleteStackByName(stackName);
    setTimeout(() => setStackDeleteWarningShown(false), 200);
  }

  return <>
    <HeaderContainer>
      <AxorTitle>Axor</AxorTitle>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isEditingName
          ? <StyledNameInput
            onChange={e => stack.rename(e.target.value)}
            value={stack.isUnnamed ? '' : stack.name}
            onBlur={onSave}
            autoFocus={true}
            placeholder={'Enter stack name'}
            onKeyDown={onNameInputKeyDown}
          />
          : (
            <StyledNameSpan
              onClick={startRename}
            >
              {stack.isUnnamed ? '' : stack.name}
            </StyledNameSpan>
          )
        }
        <Icon icon='save' onClick={onSave}/>
        <Icon icon='add' onClick={() => onStackChange(null)}/>
        <Icon icon='menu' onClick={() => setMenuShown(ms => !ms)}/>
      </div>
    </HeaderContainer>
    {menuShown ? (
      <SubHeaderContainer>
        {stacks.map(s => (
          <CellContainerStyled
            key={s.name}
            style='secondary'
            reactive={true}
            onClick={() => onStackChange(s.name)}
            noFlex={true}
          >
            <span style={{ marginRight: '0.2em' }}>{s.name}</span>
            <Icon icon={'delete'} onClick={() => onStackDelete(s.name)}/>
          </CellContainerStyled>
        ))}
      </SubHeaderContainer>
    ) : null}
  </>
});

const Footer = () => {
  return <FooterContainer>
    <span>
      Dev test version<br/>
      Made&nbsp;by&nbsp;<a href='https://dmig.dev' target='_blank'>dmig.dev</a>&nbsp;2021
    </span>
  </FooterContainer>
}

export default App;
