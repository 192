export const jsonLoad = async (req: string): Promise<any> => {
  if (req.startsWith('http')) {
    return await jsonFetch(req);
  }

  try {
    return JSON.parse(req);
  } catch (e) {
    return {
      internalError: true,
      message: 'cannot parse',
    }
  }
}

export const jsonFetch = async (url: string): Promise<any> => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    },);

    return await resp.json();
  } catch (e) {
    return {
      internalError: true,
      message: e,
    }
  }
};
