import { IStack } from '../types';
import { calcObjSize } from '../utils/object-size';

export const getStacks = (): IStack[] => {
  const stacksRaw = localStorage.getItem('stacks');
  return stacksRaw ? JSON.parse(stacksRaw): [];
}

export const upsertStack = (stack: IStack) => {
  let stacks = getStacks();

  const savedStackIndex = stacks.findIndex(s => s.name === stack.name);
  if (savedStackIndex === -1) {
    stacks.push(stack);
  } else {
    stacks = stacks.map(s => s.name === stack.name ? stack : s);
  }

  console.log({
    savingStackSize: calcObjSize(stack),
    totalStacksSize: calcObjSize(stacks),
  });

  localStorage.setItem('stacks', JSON.stringify(stacks));
}

export const deleteStackByName = (stackName: string) => {
  let stacks = getStacks();
  stacks = stacks.filter(s => s.name !== stackName);
  localStorage.setItem('stacks', JSON.stringify(stacks));
}
