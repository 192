import { IAppState } from '../types';
import React from 'react';
import { getJSONSettings } from '../json-editor/settings';


export const initialAppState: any = {
  theme: {
    primary: { color: '#279891' },
    secondary: { color: '#279891', outlined: true },
    default: { color: '#3a3a52' },
    primary_hint: { color: '#FFFFFF4D' },
    default_hint: { color: 'rgba(107,107,154,0.55)' },
    json: {
      nullColor: '#CC4432',
      numberColor: '#b0aa2b',
      stringColor: '#35b452',
      semicolonColor: '#999',
      defaultColor: '#fff',
    },
  },
  jsonSettings: getJSONSettings(),
};

const AppStateContext = React.createContext(initialAppState);
export default AppStateContext;
