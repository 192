import { IStackCellValue, IStackVariable } from '../types';
import { AxErrorCode } from '../types/error-codes';

interface IResponseVariable extends IStackCellValue {
  symbol: string;
}

export interface IRunResponse {
  variables: IResponseVariable[];
}

export interface IErrorResponse {
  error: string;
  errorCode: AxErrorCode;
}

// const API_BASE_URL = 'http://localhost:8000'
const API_BASE_URL = 'https://api.ax.dmig.xyz'

export const apiRun = async (
  current_symbol: string,
  query: string,
  variables: IStackVariable[]
): Promise<IRunResponse | IErrorResponse> => {
  const resp = await fetch(`${API_BASE_URL}/run`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      current_symbol,
      query,
      variables,
    })
  });

  return await resp.json();
}
