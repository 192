import React, { useContext } from 'react';
import { ConfigContext } from '../state/config-store';
import Cell, { ICell } from '../stack/cell';
import { JSONRecursive } from './json-cell';

const ConfigCell: React.FC<ICell> = (props) => {
  const { config, update } = useContext(ConfigContext);

  return <Cell {...props}>
    <JSONRecursive
      json={config}
      indent={0}
      expanded={true}
      path={[]}
      doUpdate={update}
      doRename={() => {
      }}
      settings={config.jsonEditor}
    />
  </Cell>;
};

export default ConfigCell;
