import { ImageCell, InputCell, TextCell, TimestampCell } from './cell';
import styled from '@emotion/styled';
import React, { Suspense } from 'react';
import StackStore from '../state/stack';
import { observer } from 'mobx-react';
import ConfigCell from '../json-editor/config-cell';
import JSONCell from '../json-editor/json-cell';


const StackContainer = styled('div')`
  width: 80%;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    width: 100%;
    flex: 1;
    max-height: calc(100vh - 80vw);
    overflow-y: scroll;
  }
`;


const Stack: React.FC<{ stack: StackStore }> = observer(({ stack }) => {
  const wrapSelectionInBrackets = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const start = e.currentTarget.selectionStart;
    const end = e.currentTarget.selectionEnd;
    if (start && end && start !== end) {
      stack.editCurrentInput(prev =>
        prev.substring(0, start) +
        '(' + prev.substring(start, end) + ')' +
        prev.substring(end));
    } else {
      return 'no-prevent';
    }
  };

  const inputKeyDownActions: Record<
    string,
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => void | 'no-prevent'
  > = {
    'Enter': stack.makeNewLine,
    'ArrowUp': stack.jumpUp,
    'ArrowDown': stack.jumpDown,
    '(': wrapSelectionInBrackets,
  };

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    for (const key in inputKeyDownActions) {
      if (e.shiftKey && e.key === 'Enter') {
        return;
      }

      if (e.key === key) {
        const preventStatus = inputKeyDownActions[key](e);
        if (preventStatus === 'no-prevent') {
          return;
        }

        return e.preventDefault();
      }
    }
  };

  const onImagePaste = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      stack.setCurrentValue({
        type: 'image',
        value: event.target?.result,
        answer: '',
        full_answer: '',
      });

      // console.log(event.target?.result); // data url
    };
    reader.readAsDataURL(file);
  };

  return <StackContainer>
    {stack.cells.map((cell, i) => (
      <React.Fragment key={i}>
        {
          stack.currentIndex === i
            ? <>
              <InputCell
                value={cell.input}
                style={'primary'}
                onChange={stack.setCurrentInput}
                onKeyDown={onInputKeyDown}
                onImagePaste={onImagePaste}
                leftOutsideText={'#' + (i + 1)}
              />
              {cell.type === 'image' ? (
                <ImageCell url={cell.value} style={'secondary'}/>
              ) : null}
              {cell.type === 'json' ? (
                <JSONCell
                  json={cell.value}
                  onRefresh={stack.onJsonRefresh}
                  style={'secondary'}
                  onDataUpdate={stack.onCurrentJsonChange}
                  onDataRename={stack.onCurrentJsonRename}
                />
              ) : null}
              {cell.type === 'config' ? (
                <ConfigCell style={'secondary'}/>
              ) : null}
              {cell.type === 'timestamp' ? (
                <TimestampCell
                  timestamp={cell.value}
                  style={'secondary'}
                />
              ) : null}
              {cell.type === 'error' ? (
                <TextCell
                  content={cell.full_answer || cell.answer}
                  style={'secondary'}
                />
              ) : null}
              {cell.type === 'number' || cell.type === 'empty' ? (
                <TextCell content={cell.full_answer || cell.answer} style={'secondary'}/>
              ) : null}
            </>
            : (cell.type === 'image' ? (
              <ImageCell
                url={cell.value}
                style={'default'}
                onClick={() => stack.jump(i)}
                leftOutsideText={'#' + (i + 1)}
                reactive={true}
              />
            ) : (
              <TextCell
                content={cell.input}
                style={'default'}
                onClick={() => stack.jump(i)}
                hint={cell.answer}
                leftOutsideText={'#' + (i + 1)}
                reactive={true}
                cellName={cell.name ?? ''}
                onCellNameChange={newName => stack.editCellName(i, newName)}
              />
            ))
        }
      </React.Fragment>
    ))}
  </StackContainer>;
});

export default Stack;
