import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import useIsMobile from '../utils/is-mobile-hook';
import { CellContainerStyled } from './cell-container';
import Icon from '../components/icon';
import { JSONRecursive } from '../json-editor/json-cell';
import { ConfigContext } from '../state/config-store';
import dayjs from 'dayjs';

const MAX_INPUT_LEN = 200;
const MAX_CELL_NAME_LEN = 80;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  margin: 0.7em 0;
  padding: 0 0.7em;

  & > *:not(section) {
    flex: 1;
    display: flex;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
`;

const CellHint = styled.span<{ stickTo: 'left' | 'right' }>`
  color: ${props => props.theme.default_hint.color};

  
  ${props => props.stickTo === 'left' ? css`
    margin-right: 0.5em;
  ` : ''}
  
  ${props => props.stickTo === 'right' ? css`
    margin-left: 0.5em;
    min-width: max-content;
  ` : ''}
`;

const OutsideHint = styled.span`
  color: ${props => props.theme.default.color};
  margin-right: 0.5em;
`;

const CellInput = styled.textarea`
  background: #0000;
  color: ${props => props.theme.commandsTextColor};
  font-size: 1em;

  outline: 0;
  border: 0;
  resize: none;

  padding: 0;

  width: 100%;

  &::placeholder {
    color: ${props => props.theme.commandsTextColor};
    opacity: 80%;
  }
`

export interface ICell {
  style: 'primary'|'secondary'|'default';
  onClick?: (e: React.MouseEvent) => void;
  leftOutsideText?: string;
  reactive?: boolean;
}

interface ITextCell extends ICell {
  content: string;
  hint?: string;
  cellName?: string;
  onCellNameChange?: (newName: string) => void;
}

interface ITimestampCell extends ICell {
  timestamp: Date;
}

interface IImageCell extends ICell {
  url: string;
}

interface IInputCell {
  value: string;
  onChange: (newValue: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onImagePaste: (file: File) => void;
  style: 'primary'|'secondary'|'default';
  leftOutsideText?: string;
}

const Cell: React.FC<ICell> = (props) => {
  return <RowContainer>
    <div style={{ justifyContent: 'end' }}>
      {props.leftOutsideText && <OutsideHint>{props.leftOutsideText}</OutsideHint>}
    </div>
    <CellContainerStyled
      style={props.style}
      onClick={props.onClick}
      reactive={!!props.reactive}
    >
      {props.children}
    </CellContainerStyled>
    <div/>
  </RowContainer>
};

// interface ICellName {
//   name: string;
//   onNameChange: (newName: string) => void;
// }
//
// const CellName: React.FC<ICellName> = (props) => {
//   const [isEditing, setIsEditing] = useState<boolean>(false);
//
//   const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
//     if (e.key === 'Enter') {
//       setIsEditing(false);
//     }
//   };
//
//   const onEditingStart = (e: React.MouseEvent) => {
//     e.stopPropagation();
//     setIsEditing(true);
//   }
//
//   return (
//     <div>
//       {isEditing ? (
//         <CellInput
//           type="text"
//           value={props.name}
//           placeholder={''}
//           autoFocus={true}
//           onChange={event => props.onNameChange(event.target.value)}
//           onKeyDown={e => onKeyDown(e)}
//           onBlur={() => setIsEditing(false)}
//           maxLength={MAX_CELL_NAME_LEN}
//           autoComplete={'hidden'}
//           spellCheck={false}
//         />
//       ) : (
//         <>
//           {props.name ? (
//             <CellHint onClick={onEditingStart}>{props.name}</CellHint>
//           ) : (
//             <Icon style={'default_hint'} icon={'create'} onClick={onEditingStart}/>
//           )}
//         </>
//       )}
//     </div>
//   );
// }

export const TimestampCell: React.FC<ITimestampCell> = (props) => {
  const { config } = useContext(ConfigContext);

  const dateString = dayjs(props.timestamp).format(config.formatting.dateFormat);

  return <Cell {...props}>
    {dateString}
  </Cell>;
};

export const TextCell: React.FC<ITextCell> = (props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
    }
  };

  const onEditingStart = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEditing(true);
  }

  return <Cell
    {...props}
  >
    {isEditing ? (
      <CellInput
        as={'input'}
        // type='text'
        value={props.cellName}
        placeholder={''}
        autoFocus={true}
        onChange={event => props.onCellNameChange && props.onCellNameChange(event.target.value)}
        onKeyDown={e => onKeyDown(e)}
        onBlur={() => setIsEditing(false)}
        maxLength={MAX_CELL_NAME_LEN}
        autoComplete={'hidden'}
        spellCheck={false}
      />
    ) : (
      <>
        <div>
          {props.hint && <CellHint stickTo={'left'}>{props.hint}</CellHint>}
          <span>
            {props.content.split('\n').map(line => (
              <React.Fragment key={line}>
                <span>{line}</span>
                <br/>
              </React.Fragment>
            ))}
          </span>
        </div>
        {!!props.onCellNameChange ? (
          <>
            {props.cellName ? (
              <CellHint onClick={onEditingStart} stickTo={'right'}>{props.cellName}</CellHint>
            ) : (
              <Icon icon={'local_offer'} onClick={onEditingStart} style='default_hint'/>
            )}
          </>
        ) : null}
      </>
    )}
  </Cell>
};

export const ImageCell: React.FC<IImageCell> = (props) => {
  return <Cell
    {...props}
  >
    <img src={props.url} alt={'image'}/>
  </Cell>
};

export const InputCell: React.FC<IInputCell> = (props) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const isMobile = useIsMobile();

  const onPaste = (event: React.ClipboardEvent) => {
    const items = (event.clipboardData).items;
    // console.log(JSON.stringify(items)); // might give you mime types
    for (const index in items) {
      const item = items[index];

      if (item.kind === 'file') {
        const blob = item.getAsFile();
        if (blob) {
          props.onImagePaste(blob);
          // console.log(blob)
          // const reader = new FileReader();
          // reader.onload = function (event) {
          //   console.log(event.target?.result); // data url
          // };
          // reader.readAsDataURL(blob);
        }
      }
    }
  };

  useEffect(() => {
    const textarea = ref.current;
    if (!textarea) return;

    const limit = Infinity;
    textarea.style.height = '';
    textarea.style.height = Math.min(textarea.scrollHeight, limit) + 'px';
  }, [ref, props.value]);

  return <Cell style={props.style} leftOutsideText={props.leftOutsideText}>
    <CellInput
      ref={ref}
      rows={1}
      value={props.value}
      placeholder={'Ask...'}
      autoFocus={!isMobile}
      onChange={event => props.onChange(event.target.value)}
      onKeyDown={e => props.onKeyDown(e)}
      maxLength={MAX_INPUT_LEN}
      autoComplete={'hidden'}
      spellCheck={false}
      onPaste={onPaste}
    />
  </Cell>
};

interface IInlineInput {
  value: string;
  onChange: (newValue: string) => void;
  onFinish: () => void;

  placeholder?: string;
  style?: CSSProperties;
  strictWidth?: boolean;
}

export const InlineInput: React.FC<IInlineInput> = (props) => {
  const inputStyle: CSSProperties = {
    ...props.style,
    width: props.value.length + 'ch',
    border: '1px solid gold',
    fontFamily: 'monospace',
    letterSpacing: 0,
  }

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange(e.target.value);
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      props.onFinish();
      e.preventDefault();
    }
  }

  return (
    <CellInput
      // type='text'
      value={props.value}
      placeholder={props.placeholder}
      autoFocus={true}
      onChange={onInputChange}
      onBlur={props.onFinish}
      onKeyDown={onKeyDown}
      // maxLength={MAX_INPUT_LEN}
      autoComplete='hidden'
      spellCheck={false}
      style={props.strictWidth ? inputStyle : props.style}
      // onPaste={onPaste}
    />
  );
}

export default Cell;
