import { IJsonConfig } from '../types/json-editor';

export const getJSONSettings = (overrides?: Partial<IJsonConfig>): IJsonConfig => {
  const settings: IJsonConfig = {
    paddingSize: 0.9,
    keyQuoting: false,
    commasOnEnd: false,
    enableBrackets: false,
    expandAlwaysOnTop: false,
    previewsEnabled: true,
    previewsThreshold: 60,
    ...overrides,
  };

  if (!settings.enableBrackets && settings.commasOnEnd) {
    settings.commasOnEnd = false;
  }

  return settings;
};
