import React, { CSSProperties } from 'react';
import { useTheme } from '@emotion/react';

interface IIcon {
  icon: string;
  style?: 'default'|'primary_hint'|'default_hint';
  onClick?: (e: any) => void;
  paddingLeft?: number; // em
}

const Icon: React.FC<IIcon> = (props) => {
  const { icon, style = 'default', onClick, paddingLeft } = props;
  const theme = useTheme();
  const color = theme[style].color;

  const passProps: { className: string, onClick: any, style: CSSProperties } = {
    className: 'material-icons',
    onClick,
    style: { color, cursor: onClick ? 'pointer' : 'default' },
  };

  if (paddingLeft !== undefined && paddingLeft !== null) {
    passProps.style.paddingLeft = paddingLeft + 'em';
  }

  return (
    onClick
      ? <button {...passProps}>{icon}</button>
      : <span {...passProps}>{icon}</span>
  );
}

export default Icon;
