export const calcEstimateObjectSize = (object: any) => {
  let objectList = [];
  let stack = [object];
  let bytes = 0;

  while (stack.length) {
    let value = stack.pop();

    if (typeof value === 'boolean') {
      bytes += 4;
    } else if (typeof value === 'string') {
      bytes += value.length * 2;
    } else if (typeof value === 'number') {
      bytes += 8;
    } else if (
      typeof value === 'object'
      && objectList.indexOf(value) === -1
    ) {
      objectList.push(value);

      for (let i in value) {
        stack.push(value[i]);
      }
    }
  }

  return bytes;
};

export const calcObjSize = (object: any) => {
  let size = calcEstimateObjectSize(object);
  let ord = 0
  const ords = ['B', 'KB', 'MB', 'GB', 'TB']
  while (size / 1024 > 1 && (ord+1) < ords.length) {
    ord++;
    size = size / 1024;
  }

  return size.toFixed(2).toString() + ords[ord];
}
