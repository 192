const CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789.';
const CHARACTERS_SMALL = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';

const genRandomString = (chars: string, length: number) => {
  let result = '';
  const charactersLength = chars.length;
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

// Generates unique ID for stack
export const genRandomStackID = () => {
  return '&' + genRandomString(CHARACTERS_SMALL, 2);
}
