import { IStack, IStackCell, IStackCellValue, IStackVariable } from '../types';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { apiRun, IRunResponse } from '../api/calc-api';
import { indexToV100, V100ToIndex } from '../utils/var-names';
import { getStacks, upsertStack } from '../api/storing-api';
import { genRandomStackID } from '../utils/randomness';
import { jsonFetch, jsonLoad } from '../api/json-viewer-api';

type IHandler = 'resolved'|'pass';

const DEBOUNCE_MS = 50;
const AUTOSAVE_MS = 6000;

const EMPTY_CELL: IStackCell = {
  input: '',
  type: 'empty',
  value: null,
  answer: '',
};

class StackStore implements IStack {
  id: string = genRandomStackID();
  name: string = 'unnamed';

  variables: IStackVariable[] = [];
  cells: IStackCell[] = [EMPTY_CELL];
  currentIndex = 0;

  lastTimeoutId: NodeJS.Timeout | null = null;
  lastSaveTimeoutId: NodeJS.Timeout | null = null;

  constructor() {
    makeAutoObservable(this, { jump: action } );

    // Was needed for demos, maybe in future too
    // runInAction(this.quickFill);
  }

  jump = (cell: number) => {
    const c = this.cells[this.currentIndex]
    const shouldDestroy = !c?.input && !(c?.type === 'image' && !!c?.value)
    if (shouldDestroy) {
      this.cells.splice(this.currentIndex, 1);
      if (cell >= this.currentIndex) {
        cell -= 1;
      }
    }

    this.currentIndex = cell;
  }

  jumpUp = () => {
    if (this.currentIndex > 0) {
      this.jump(this.currentIndex - 1)
    }
  }

  jumpDown = () => {
    if (this.currentIndex < this.cells.length - 1) {
      this.jump(this.currentIndex + 1)
    }
  }

  setCurrentInput = (newInput: string) => {
    this.cells[this.currentIndex].input = newInput;
    this.onUserAsk();
  }

  onUserAsk = () => {
    if (this.lastTimeoutId) {
      clearTimeout(this.lastTimeoutId);
    }

    this.lastTimeoutId = setTimeout(async () => {
      const status = await this.onUserAskLocalHandler();
      if (status === 'pass') {
        await this.onUserAskApi();
      }
    }, DEBOUNCE_MS);
  }

  onUserAskLocalHandler = async (): Promise<IHandler> => {
    const commands = [
      this.onJsonCommand,
      this.onConfigCommand,
      this.onLenCommand,
      this.onStackCommand,
      this.onSnowflakeCommand,
    ];
    for (const command of commands) {
      const s = await command();
      if (s === 'resolved') {
        return 'resolved';
      }
    }

    return 'pass';
  }

  onJsonCommand = async (): Promise<IHandler> => {
    if (!this.currentInput.startsWith('!json')) {
      return 'pass';
    }

    this.cells[this.currentIndex] = {
      input: this.currentInput,
      type: 'json',
      value: this.cells[this.currentIndex].type === 'json'
        ? this.cells[this.currentIndex].value
        : {},
      answer: '',
      name: this.cells[this.currentIndex].name,
    };

    return 'resolved';
  }

  onConfigCommand = async (): Promise<IHandler> => {
    if (!this.currentInput.startsWith('!config')) {
      return 'pass';
    }

    this.cells[this.currentIndex] = {
      input: this.currentInput,
      type: 'config',
      value: '',
      answer: '',
      name: this.cells[this.currentIndex].name,
    }

    return 'resolved';
  }

  onLenCommand = async (): Promise<IHandler> => {
    const match = this.currentInput.match(/len\("(.*)"\)/);
    if (!match) {
      return 'pass';
    }

    const len = match[1].length;

    this.cells[this.currentIndex] = {
      input: this.currentInput,
      type: 'number',
      value: len,
      answer: len + '',
      name: this.cells[this.currentIndex].name,
    }

    return 'resolved';
  }

  onSnowflakeCommand = async (): Promise<IHandler> => {
    const match = this.currentInput.match(/^!(snowflake)? (\d+)$/);
    console.log({ match })
    if (!match) {
      console.log('no pass')
      return 'pass';
    }

    const isStrict = !!match[1];
    const num = match[2];

    const fail = (reason: string) => {
      this.cells[this.currentIndex] = {
        input: this.currentInput,
        type: 'error',
        value: reason,
        answer: reason,
        name: this.cells[this.currentIndex].name,
      }
    }

    console.log('meow 1')

    if (!Number.isInteger(+num)) {
      if (!isStrict) {
        return 'pass';
      }

      fail('Not a valid number for snowflake');
      return 'resolved';
    }

    console.log('meow 2')

    // @ts-ignore
    const date = new Date(Number(BigInt(num) >> 22n) + 1420070400000);

    console.log('meow 3', date)

    if (Number.isNaN(date.getTime())) {
      if (!isStrict) {
        return 'pass';
      }

      fail('Can\'t convert to date');
      return 'resolved';
    }

    console.log('meow')

    this.cells[this.currentIndex] = {
      input: this.currentInput,
      type: 'timestamp',
      value: date,
      answer: '',
      name: this.cells[this.currentIndex].name,
    }

    return 'resolved';
  }

  onStackCommand = async (): Promise<IHandler> => {
    if (!this.currentInput.startsWith('!stack')) {
      return 'pass';
    }

    const showAllStacks = this.currentInput.startsWith('!stacks');
    const data = showAllStacks
      ? getStacks().map(s => ({ id: s.id, name: s.name }))
      : { id: this.id, name: this.name };

    this.cells[this.currentIndex] = {
      input: this.currentInput,
      type: 'json',
      value: data,
      answer: this.id,
      name: this.cells[this.currentIndex].name,
    }

    return 'resolved';
  }

  onJsonRefresh = async () => {
    const req = this.currentInput.substr(5).trim();

    this.cells[this.currentIndex] = {
      input: this.currentInput,
      type: 'json',
      value: await jsonLoad(req),
      answer: '',
      name: this.cells[this.currentIndex].name,
    };
  }

  onCurrentJsonChange = (path: (string|number)[], value: any) => {
    let obj: any = this.cells[this.currentIndex].value;
    for (const i of path.slice(0, path.length - 1)) {
      obj = obj[i];
    }
    obj[path[path.length - 1]] = value;
  }

  onCurrentJsonRename = (path: (string|number)[], newName: string) => {
    let obj: any = this.cells[this.currentIndex].value;
    for (const i of path.slice(0, path.length - 1)) {
      obj = obj[i];
    }
    obj[newName] = obj[path[path.length - 1]];
    delete obj[path[path.length - 1]];
  }

  onUserAskApi = async () => {
    const targetStackID = this.id;
    const currentSymbol = indexToV100(this.currentIndex);
    const variables = this.cells.map((cell, i) => ({
      symbol: indexToV100(i),
      type: cell.type,
      value: cell.value,
      expr: cell.input,
    }));

    console.log(`sent to api; ${targetStackID}`);
    const resp = await apiRun(currentSymbol, this.currentInput, variables);
    console.log({ resp })

    let affectedStack: StackStore = this;
    if (targetStackID !== this.id) {
      console.log(`${targetStackID} ${this.id}`);

      const st = getStacks().find(s => s.id === targetStackID)

      if (!st) {
        console.warn('Received API response for non-existing stack');
        return;
      }

      affectedStack = new StackStore();
      affectedStack.load(st);
    } else {
      affectedStack.tryToStartAutoSaveTimeout();
    }

    if ('error' in resp) {
      let content = ''
      if (resp.errorCode === 'AX1000') {
        content = 'Internal error';
      } else if (resp.errorCode.startsWith('AX12')) {
        content = resp.error;
      }
      affectedStack.setCurrentValue({ type: 'empty', value: null, answer: content });
    } else {
      affectedStack.updateFromApiResp(resp, targetStackID);
    }

    if (targetStackID !== this.id) {
      upsertStack(affectedStack);
    }
  }

  tryToStartAutoSaveTimeout = () => {
    if (!this.lastSaveTimeoutId && !this.isUnnamed) {
      this.lastSaveTimeoutId = setTimeout(async () => {
        upsertStack(this);
        this.lastSaveTimeoutId = null;
      }, AUTOSAVE_MS);
    }
  }

  setCurrentValue = (value: IStackCellValue) => {
    this.cells[this.currentIndex] = { ...this.cells[this.currentIndex], ...value };
  }

  editCurrentInput = (fn: (previousInput: string) => string) => {
    this.setCurrentInput(fn(this.cells[this.currentIndex].input));
  }

  editCellName = (cellIndex: number, newName: string) => {
    this.cells[cellIndex].name = newName;
  }

  makeNewLine = () => {
    this.onUserAskApi();
    this.cells.push(EMPTY_CELL);
    this.jump(this.cells.length - 1);
  }

  rename = (newName: string) => {
    this.name = newName;
  }

  load = (stackData?: IStack) => {
    if (!stackData) {
      return;
    }

    Object.assign(this, stackData);
    this.clearTemporaryFields();
  }

  clearTemporaryFields = () => {
    this.lastTimeoutId = null;
    this.lastSaveTimeoutId = null;
  }

  updateFromApiResp = (resp: IRunResponse, targetStackID: string) => {
    resp.variables.forEach(v => {
      const cellIndex = V100ToIndex(v.symbol);
      this.cells[cellIndex] = {
        input: this.cells[cellIndex].input,
        type: v.type,
        value: v.value,
        answer: v.answer,
        full_answer: v.full_answer,
        name: this.cells[cellIndex].name,
      };
    })
  }

  loadEmpty = () => {
    this.id = genRandomStackID();
    this.name = 'unnamed';

    this.variables = [];
    this.cells = [EMPTY_CELL];
    this.currentIndex = 0;
  }

  quickFill = () => {
    this.cells = [
      {
        input: '27 + 17 - 11',
        type: 'number',
        value: 33,
        answer: '33',
      },
      {
        input: '(917-915)^2',
        type: 'number',
        value: 4,
        answer: '4',
        name: 'Middle calculation',
      },
      {
        input: '#1 - #2+7',
        type: 'number',
        value: 36,
        answer: '36',
      },
    ];
    this.currentIndex = this.cells.length-1;
  }

  get isUnnamed() {
    return this.name === 'unnamed';
  }

  get isEmpty(): boolean {
    if (this.cells.length > 1) return false;

    if (this.cells.length === 0) return true;
    if (this.cells[0].type === 'empty') return true;

    return false;
  }

  get currentInput() {
    return this.cells[this.currentIndex].input;
  }
}

export default StackStore;
