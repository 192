import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';
import React from 'react';

interface ICellContainer {
  outlined: boolean;
  color: string;
  reactive: boolean;
  noFlex?: boolean;
}

export const CellContainer = styled.section<ICellContainer>`
  ${props => props.noFlex ? '' : css`flex: 18;`}
  padding: ${props => props.outlined ? `${0.8 - 0.16}em` : '0.8em'};
  min-height: 2.8em;
  
  max-width: 90%;

  box-shadow: 0 0.15em 0.15em rgba(0, 0, 0, 0.25);
  border-radius: 0.7em;
  
  display: flex;
  justify-content: space-between;
  
  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  ${props => props.outlined ? css`
    border: 0.16em solid ${props.color};
  ` : css`
    background: ${props.color};
  `}
  
  ${props => props.reactive && css`
    &:hover {
      filter: brightness(96%);
      transition: filter 0.1s;
    }
    &:active {
      filter: brightness(88%);
      transition: filter 0.1s;
    }
  `}
`;

interface ICellContainerStyled {
  style: 'primary' | 'secondary' | 'default';
  onClick?: (e: React.MouseEvent) => void;
  reactive?: boolean;
  noFlex?: boolean;
}

export const CellContainerStyled: React.FC<ICellContainerStyled> = props => {
  const theme = useTheme();
  const cellStyle = theme[props.style];

  return (
    <CellContainer
      outlined={!!cellStyle.outlined}
      color={cellStyle.color}
      onClick={props.onClick}
      reactive={!!props.reactive}
      noFlex={props.noFlex}
    >
      {props.children}
    </CellContainer>
  );
};
