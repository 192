import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import StackStore from '../state/stack';

const KeyboardContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const KeyboardBody = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  height: calc(100vw/5*4);
  padding: 0.7em;
  grid-gap: 0.7em;
`;

const KeyButtonWrapper = styled.div<{ double: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${props => props.double ? css`
    grid-column-end: 6;
    grid-column-start: 4;
  ` : ''}
`;

const KeyButtonContainer = styled.button<{ double: boolean }>`
  background: rgb(58, 58, 82);
  border-radius: 25%;
  font-size: 2em;
  width: 100%;
  height: 100%;
  color: rgb(178, 178, 202);
  box-shadow: 0 0.3em 0.3em rgba(0, 0, 0, 0.25);

  &:hover {
    filter: brightness(96%);
    transition: filter 0.1s;
  }
  
  &:active {
    filter: brightness(88%);
    transition: filter 0.1s;
  }

  ${props => props.double ? css`
    border-radius: 12.5%/25%;
  ` : ''}
`;

type AxorKeyboardKey = 7 | 8 | 9 | '()' | 'D' | 6 | 5 | 4 | '+' | '-' | 3 | 2 | 1 | '*' | '/' | 0 | '.' | '<>' | '#' | 'E';

interface IKeyButton {
  value: AxorKeyboardKey;
  onClick: (key: AxorKeyboardKey) => void;
  isDouble: boolean;
}

const KeyButton: React.FC<IKeyButton> = ({ value, onClick, isDouble }) => {
  return <KeyButtonWrapper double={isDouble}>
    <KeyButtonContainer onClick={() => onClick(value)} double={isDouble}>
      {value === 'E' ? 'Axor' : value}
    </KeyButtonContainer>
  </KeyButtonWrapper>
}

const KeyboardRoot: React.FC<{ stack: StackStore }> = observer(({ stack }) => {
  const [lastClick, setLastClick] = useState<AxorKeyboardKey|null>(null);

  const allKeys: AxorKeyboardKey[] = [7,8,9,'()','D',6,5,4,'+','-',3,2,1,'*','/',0,'.','#','E'];
  const operands: AxorKeyboardKey[] = ['+', '-', '*', '/'];

  const onClick = useCallback((key: AxorKeyboardKey) => {
    setLastClick(key);
    if (key === 'D') {
      stack.editCurrentInput(i => i.trimEnd().substring(0, i.trimEnd().length - 1));
      return;
    }

    if (key === 'E') {
      stack.makeNewLine();
      return;
    }

    let toPlace = '';
    let trimEnd = false;

    if (key === '()') {
      if (lastClick === '()') {
        stack.editCurrentInput(i => i.substring(0, i.length - 1) + ')');
        return;
      } else {
        toPlace = '(';
      }
    } else if (key === '#') {
      stack.editCurrentInput(i => {
        if (lastClick !== '#') return i + '#' + (stack.cells.length - 1);
        const lastVal = +i[i.length - 1];
        if (lastVal === 1) return i;
        return i.substring(0, i.length - 1) + (lastVal - 1)
      });
    } else if (operands.includes(key)) {
      toPlace = ' ' + key + ' ';
      trimEnd = true;
    } else if (lastClick === '#' && typeof key === 'number') {
      stack.editCurrentInput(i => i.substring(0, i.length - 1) + key);
      return;
    } else {
      toPlace = key + '';
    }

    stack.editCurrentInput(i => (trimEnd ? i.trimEnd() : i) + (toPlace + ''));
  }, [lastClick]);

  return <KeyboardContainer>
    <KeyboardBody>
      {allKeys.map((v, index) => (
        <KeyButton key={index} value={v} onClick={onClick} isDouble={v === 'E'}/>
      ))}
    </KeyboardBody>
  </KeyboardContainer>;
});

export default KeyboardRoot;
